// @ts-nocheck
'use client';
import { Fragment, useState } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { signIn, signOut } from 'next-auth/react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import Image from 'next/image';

const navigation = {
  pages: [
    ,
    { name: 'Strona Główna', href: '/' },
    { name: 'O nas', href: '/onas' },
    { name: 'Produkty', href: '/produkty' },
    { name: 'Usługi', href: '/uslugi' },
    {
      name: 'Rozkrój Płyt',
      href: 'https://erozrys.mobene.pl/pl/profile/login',
    },
    { name: 'Aktualności', href: '/blog' },
    { name: 'Kontakt', href: '/kontakt' },
  ],
};

export default function AppBar() {
  const [open, setOpen] = useState(false);
  const { data: session } = useSession();

  return (
    <div className="bg-white">
      {/* Mobile menu */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg::hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <Link
                        rel="noopener noreferrer"
                        //target="_blank"
                        href={page.href}
                        key={page.name}
                        className="-m-2 block p-2 font-medium text-gray-900 "
                      >
                        {page.name}
                      </Link>
                    </div>
                  ))}
                </div>

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  <div className="">
                    <button className="-m-2 block p-2 font-medium text-gray-900">
                      Stwórz Konto
                    </button>
                  </div>
                  <div className="flow-root">
                    <a
                      href="#"
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      Zaloguj się
                    </a>
                  </div>
                </div>

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {/* Currency selector */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        <nav aria-label="Top">
          {/* Top navigation */}
          <div className="bg-gray-900">
            <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
              {/* Currency selector */}
              <div className="hidden lg:block lg:flex-1">
                <div className="flex">
                  <div className="group relative -ml-2 rounded-md border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white">
                    {/* Na wypadek gdybyśmy dodawali zmianę języka strony
                    
                    <select
                      id="desktop-currency"
                      name="currency"
                      className="flex items-center rounded-md border-transparent bg-gray-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
                    >
                      <option>PL</option>
                      <option>EN</option>
                    </select> */}
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center"></div>
                  </div>
                </div>
              </div>
              {
                // Wersja Strony Komputerowej
              }

              {session?.user ? (
                session?.user?.role === 'Admin' ? (
                  <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    <Link
                      href={'/admin/'}
                      className="text-sm font-medium text-white hover:text-gray-100"
                    >
                      Panel Administratora
                    </Link>
                    <span className="h-6 w-px bg-gray-600" aria-hidden="true" />
                    <button
                      onClick={() => {
                        signOut();
                      }}
                      className="text-sm font-medium text-white hover:text-gray-100"
                    >
                      Wyloguj się
                    </button>
                  </div>
                ) : (
                  <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    <Link
                      href={'/client/dashboard'}
                      className="text-sm font-medium text-white hover:text-gray-100"
                    >
                      Panel Klienta
                    </Link>
                    <span className="h-6 w-px bg-gray-600" aria-hidden="true" />
                    <button
                      onClick={() => {
                        signOut();
                      }}
                      className="text-sm font-medium text-white hover:text-gray-100"
                    >
                      Wyloguj się
                    </button>
                  </div>
                )
              ) : (
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6 ">
                  <button className="text-sm font-medium text-white hover:text-gray-100">
                    Stwórz konto
                  </button>
                  <span className="h-6 w-px bg-gray-600" aria-hidden="true" />
                  <button
                    onClick={() => {
                      signIn('google');
                    }}
                    className="text-sm font-medium text-white hover:text-gray-100"
                  >
                    Zaloguj się
                  </button>
                </div>
              )}
              {}
              {
                // Koniec wersji Komputerowej
              }
            </div>
          </div>

          {/* Secondary navigation */}
          <div className="bg-white ">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="">
                <div className="flex flex-row justify-end items-center py-2 gap-2">
                  <a
                    href="https://www.facebook.com/mobenecentrumstolarskie"
                    aria-label="Facebook"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 fill-slate-400"
                      viewBox="0 0 24 24"
                    >
                      <title>Facebook</title>
                      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.youtube.com/@mobenemeble897/videos"
                    aria-label="YouTube"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 fill-slate-400"
                      viewBox="0 0 24 24"
                    >
                      <title>YouTube</title>
                      <path d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/TwojProfil"
                    aria-label="Instagram"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 fill-slate-400"
                      viewBox="0 0 24 24"
                    >
                      <title>Instagram</title>
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                  </a>
                </div>
                <div className="border-b border-gray-200">
                  <div className="flex h-24 items-center justify-between">
                    {/* Logo (lg+) */}
                    <div className="hidden lg:flex lg:items-start ">
                      <a href="/">
                        <span className="sr-only">Mobene</span>
                        <Image
                          className="h-24 w-auto mb-6"
                          src="/logo/logo.png"
                          alt=""
                          width={300}
                          height={100}
                        />
                      </a>
                    </div>
                    {
                      // menu normalne
                    }
                    <div className="hidden h-full lg:flex items-center py-4">
                      {/* Mega menus */}
                      <Popover.Group className="ml-20">
                        <div className="flex h-full justify-center space-x-8">
                          {navigation.pages.map((page) => (
                            <Link
                              rel="noopener noreferrer"
                              //target="_blank"
                              key={page.name}
                              href={page.href}
                              className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                            >
                              {page.name.toLocaleUpperCase()}
                            </Link>
                          ))}
                        </div>
                      </Popover.Group>
                    </div>

                    {/* Mobile menu and search (lg-) */}
                    <div className="flex flex-1 items-center lg:hidden">
                      <button
                        type="button"
                        className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                        onClick={() => setOpen(true)}
                      >
                        <span className="sr-only">Open menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Search */}
                    </div>

                    {/* Logo (lg-) */}
                    <a href="/" className="lg:hidden">
                      <span className="sr-only">Mobene</span>
                      <Image
                        className="h-24 w-auto mb-5"
                        src="/logo/logo.png"
                        alt=""
                        width={200}
                        height={100}
                      />
                    </a>

                    <div className="flex flex-1 items-center justify-end">
                      <div className="flex items-center lg:ml-8">
                        <div className="flex space-x-8">
                          <div className="hidden lg:flex group relative">
                            <a
                              alt="xd"
                              href="https://erozrys.mobene.pl/pl/profile/login"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">eRozrys</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                className="fill-gray-400 h-[1.6rem] w-auto"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 13.269v-2.538l-2.108-.002c-.059-.457-.148-.904-.267-1.34l1.947-.809-.972-2.345-1.948.805c-.228-.396-.482-.775-.761-1.135l1.491-1.493-1.795-1.794-1.493 1.49c-.359-.278-.738-.533-1.135-.76l.805-1.949-2.344-.971-.809 1.947c-.436-.118-.883-.208-1.34-.266l-.001-2.109h-2.539l-.002 2.109c-.457.058-.904.148-1.34.266l-.809-1.947-2.345.971.805 1.949c-.396.227-.775.482-1.135.76l-1.493-1.49-1.794 1.794 1.49 1.493c-.278.36-.533.739-.76 1.135l-1.949-.805-.971 2.345 1.947.809c-.118.436-.208.883-.266 1.34l-2.109.002v2.538l2.109.002c.058.457.148.904.266 1.34l-1.947.809.971 2.345 1.949-.805c.228.396.482.775.76 1.135l-1.49 1.493 1.794 1.794 1.493-1.49c.36.278.739.533 1.135.76l-.805 1.949 2.345.971.809-1.947c.436.118.883.208 1.34.266l.002 2.109h2.539l.002-2.109c.457-.058.904-.148 1.34-.266l.809 1.947 2.345-.971-.805-1.949c.396-.228.775-.482 1.135-.76l1.493 1.49 1.795-1.794-1.491-1.493c.278-.36.533-.739.761-1.135l1.948.805.972-2.345-1.947-.809c.118-.436.208-.883.267-1.34l2.106-.002zm-12 2.731c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
                              </svg>
                            </a>
                            <span
                              className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
                            >
                              eRozrys
                            </span>
                          </div>
                          <div className="hidden lg:flex group relative">
                            <a
                              href="#"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">Search</span>
                              <MagnifyingGlassIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </a>
                            <span
                              className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
                            >
                              Szukaj
                            </span>
                          </div>

                          <div className="flex group relative">
                            <a
                              href="/client"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">Account</span>
                              <UserIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </a>
                            <span
                              className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-sm absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto"
                            >
                              Zaloguj
                            </span>
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
